<template>
  <div class="meter-device-reading-form">
    <div class="pa-4 text-center">
      <img src="~@images/logo.svg" class="logo" alt="">

      <!-- Message -->
      <div v-if="!loaded || sendSuccess || !tokenIsValid">
        <v-row class="mb-16" v-if="loaded">
          <v-col>
            <!-- Success -->
            <div class="title mt-16" v-if="sendSuccess">
              {{ $t('meter-device.reading.form-success') }}
            </div>
            <!-- Token not valid -->
            <div class="title mt-16" v-else-if="!tokenIsValid">
              {{ $t('invalid-token') }}
            </div>
          </v-col>
        </v-row>

        <div class="car">
          <img src="~@images/car.front.png" />
        </div>
      </div>
      <!-- Form -->
      <div v-else>
        <v-card>
          <v-card-text>
            <headline bg>
              {{ $t("meter-device.reading.title") }}
            </headline>

            <div class="grid">
              <div v-if="customer !== null" class="mx-6">
                <!-- Description text -->
                <v-row>
                  <div class="pl-6 pr-6">
                    {{ $t("meter-device.reading.form-description") }}
                  </div>
                </v-row>

                <headline>
                  <v-col>{{ $t("customer.informations") }}</v-col>
                </headline>

                <!-- Organization -->
                <v-row>
                  <v-col>
                    <v-text-field v-model="customer.organization" readonly :label="$t('organization.organization')"></v-text-field>
                  </v-col>
                </v-row>

                <!-- Customer infos -->
                <v-row>
                  <v-col>
                    <v-text-field v-model="customer.firstname" readonly :label="$t('firstname')"></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field v-model="customer.lastname" readonly :label="$t('lastname')"></v-text-field>
                  </v-col>
                </v-row>

                <!-- RELEVES -->

                <headline>{{ $tc('meter-device.reading.meter', 2) }}</headline>

                  <v-container fluid>

                    <v-form ref="form" @submit.prevent="submitForm">
                      <v-row justify="center" align="center" v-for="(reading, index) in form.meterReadings" :key="index" style="border: none;">
                        <v-col cols="12" md="3">
                          <!-- Meter device -->
                          <v-select
                              v-model="reading.meterDeviceId"
                              :items="customer.meterDevices"
                              :label="$t('meter-device.meter')"
                              :rules="[commonRules.required]"
                              item-text="name"
                              item-value="id"
                              :readonly="needConfirmation"
                          ></v-select>
                        </v-col>

                        <v-col cols="12" md="2">
                          <!-- Value -->
                          <v-text-field
                              v-model.number="reading.readingValue"
                              :label="$t('value')"
                              :hint="$t('meter-device.reading.comma-needed-hint')"
                              :suffix="$t('usages.kwh')"
                              :rules="[commonRules.required]"
                              oninput="this.value = this.value ? this.value : 0"
                              :readonly="needConfirmation"
                              style="width: 100%; max-width: 300px; /* Corrige un bug graphique quand on focus le champs (le form changeait de width) */"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="2">
                          <!-- Date -->
                          <date-picker
                              v-model="reading.readingDate"
                              :label="$t('date')"
                              :rules="[commonRules.required]"
                              :readonly="needConfirmation"
                          ></date-picker>
                        </v-col>

                        <v-col cols="12" md="3">
                          <v-file-input
                              v-model="reading.picture"
                              :label="$t('photo')"
                              accept="image/*"
                              show-size
                              append-icon="mdi-camera"
                              :rules="[commonRules.required]"
                              :disabled="needConfirmation"
                          ></v-file-input>
                        </v-col>

                        <!-- Boutton supprimer -->
                        <v-col cols="12" md="2" v-if="!needConfirmation">
                          <v-btn small @click="removeMeterReading(index)" color="error">
                            <v-icon left>mdi-trash-can</v-icon>
                            {{ $t("delete") }}
                          </v-btn>
                        </v-col>
                      </v-row>

                      <!-- Bouton ajout de relevé -->
                      <v-row justify="center" class="mt-6" v-if="!needConfirmation">
                        <v-btn small @click="addMeterReading">
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </v-row>

                      <!-- Submit form -->
                      <v-row justify="center" class="mt-6">
                        <!-- Preview -->
                        <v-col cols="12" md="3">
                          <v-btn color="primary" @click="confirmPreview()" block v-if="needConfirmation">{{ $t('validate') }}</v-btn>
                          <v-btn color="primary" type="submit" block v-else>{{ $t('preview') }}</v-btn>
                        </v-col>
                      </v-row>
                    </v-form>

                  </v-container>

              </div>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import MeterDeviceRepository from "@repository/MeterDeviceRepository";
import RulesMixin from "@mixins/RulesMixin";

export default {
  name: 'MeterDeviceReadingForm',
  mixins: [RulesMixin],
  data() {
    return {
      tokenIsValid: false,
      form: {
        meterReadings: [],
      },
      customer: null,
      sendSuccess: false,
      loaded: false,
      needConfirmation: false,
    }
  },

  mounted() {
    this.checkToken()
  },

  methods: {
    submitForm() {
      if(this.$refs.form.validate()) {
        this.needConfirmation = true
      }
    },

    confirmPreview() {
      this.showLoading(true)

      console.log(this.form.meterReadings)

      let readings = this.form.meterReadings.map(reading => {
        return {
          meterDeviceId: reading.meterDeviceId,
          readingValue: reading.readingValue * 1000,
          readingDate: reading.readingDate,
          picture: reading.picture
        }
      })

      MeterDeviceRepository.addManualMeterReadings(this.token, readings).then((res) => {
        if(res.errors.length > 0) {
          this.notifyError(res.errors[0])
          return
        }
        this.$dialog.notify.success(this.$t('add-success'))
        this.showLoading(false)
        this.sendSuccess = true
      }).catch(e => {
        this.showLoading(false)
        this.$dialog.notify.error(e.response.data.message)
        console.log(e.response.data.message)
      })
    },

    checkToken() {
      const token = decodeURIComponent(this.$route.params.token)

      this.loaded = false
      this.showLoading(true)

      MeterDeviceRepository.checkMeterDevicesReadingsToken(token).then((res) => {
        this.loaded = true
        this.customer = res
        this.tokenIsValid = true
        this.token = token
        this.addMeterReading()
        this.showLoading(false)
      }).catch(e => {
        console.log(e.response.data.message)
        this.tokenIsValid = false
        this.loaded = true

        this.showLoading(false)
      })
    },

    addMeterReading() {
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString().slice(0, 16);

      // Sélection du prochain compteur
      const meterDeviceID = this.customer && this.customer.meterDevices.length > 0 ? this.customer.meterDevices[0].id : null;
      this.form.meterReadings.push({ meterDeviceId: meterDeviceID, readingValue: null, readingDate: formattedDate, picture: null });
    },

    removeMeterReading(index) {
      this.form.meterReadings.splice(index, 1);
    },

    clearForm() {
      this.form.meterReadings = []
      this.$refs.form.reset()
    }
  },
}
</script>

<style lang="scss">
@media (max-width: 600px) {
  .meter-device-reading-form {
    height: auto!important;
    .logo {width: 100%;margin-top: 50px}
  }
}
</style>